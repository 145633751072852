import type { DetailPage, MenuProduct, ProdCategory, Product, SpecModel } from "~/model/product"
import { buildApi } from '@/base/lib';
// import type { HttpResult } from "~/base/http";
import { getFetch, getUseFetch,  type HttpContext } from "~/base/http";
import { menuCategoryList, menuProductGroup } from "~/stores/index";
import type { ObjectId } from "~/base/types";

export async function getProdCategoryList(ctx:HttpContext):Promise<void> {
    const url = buildApi('prodcategory/get-list')
    let result = await getUseFetch(ctx, url)
    
    if(result.isOk){
        Object.assign(menuCategoryList, result.response.list as ProdCategory[])
    }
}

export async function getProdCategoryName(ctx:HttpContext, prodCategoryId: string):Promise<string> {
    const url = buildApi(`prodcategory/get-name/${prodCategoryId}`)
    let result = await getUseFetch(ctx, url)
    
    if(result.isOk){
        return result.response as string
    }
    return ''
}

// export async function getProductList(): Promise<Product[]> {
//     const url = buildApi('product/get-list')
//     const response = await useFetch(url)
//     const result = response.data.value as HttpResult
    
//     return result.response.list as Product[]
// }
export async function getMenuProduct(ctx:HttpContext): Promise<void> {
    const url = buildApi(`product/get-menu-product`)
    const result = await getUseFetch(ctx, url)
    if(!result.isOk){
        return
    }
    const productList = result.response.productList as Product[]
    const specModelList = result.response.specModelList as SpecModel[]

    const findSpecModel = (specModelId: ObjectId) => {
        for (const sm of specModelList) {
            if (specModelId == sm.specModelId) {
                return sm
            }
        }
        throw new Error(`spec model not found: ${specModelId}`)
    }
    
    let menuProductList: MenuProduct[] = []
    for (const product of productList) {
        const specModel = findSpecModel(product.productId)
        menuProductList.push({
            product:product,
            specList:specModel.specList,
            modelList:specModel.modelList,
            selectedList:[]
        })
    }
    sortMenuProductList(menuProductList)
    // return result.response.list as Product[]
}

function sortMenuProductList(menuProductList:MenuProduct[]){
    let categoryIndexMap = new Map<string, number>()
    let groupList: MenuProduct[][] = new Array<MenuProduct[]>(menuCategoryList.length)
    for (let i = 0; i < menuCategoryList.length; i++) {
        categoryIndexMap.set(menuCategoryList[i].prodCategoryId, i)
        groupList[i] = []
    }
    
    for (const product of menuProductList) {
        const index = categoryIndexMap.get(product.product.categoryId)
        if (index !== undefined) {
            groupList[index].push(product)
        }
    }

    for (const menuProductOfCategory of groupList) {
        menuProductOfCategory.sort((a, b) => {
            return a.product.sort - b.product.sort
        })
    }
    menuProductGroup.length = 0
    Object.assign(menuProductGroup, groupList)
}

export async function getProductById(ctx:HttpContext, productId: string): Promise<Product|undefined> {
    const url = buildApi(`product/get-product-by-id/${ productId }`)
    let result = await getFetch(ctx, url)

    if(result.isOk){
        return result.response as Product
    }
    return undefined
}

export async function getProductDetailPage(ctx:HttpContext, productId: string): Promise<DetailPage|undefined> {
    const url = buildApi(`product/get-detail-page/${ productId }`)
    let result = await getFetch(ctx, url)

    if(result.isOk){
        return result.response as DetailPage
    }
    return undefined
}